













import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { User } from '@/models/user'
import AuthStore from '@/store/AuthStore'
import companyCollection from '@/models/company'

@Component({
  components: {}
})
export default class UserItemCompany extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<User>

  get item (): User {
    return this.doc.data()
  }

  get selectCompanies (): { value: string, text: string }[] {
    return AuthStore.companies.map(doc => {
      return { value: doc.id, text: doc.data()?.name || '' }
    })
  }

  get companies (): string[] {
    return this.item.companies.map(doc => doc.id)
  }

  update (vals: string[]): void {
    const companies = vals.map(val => companyCollection.doc(val))
    this.doc.ref.set({ companies }, { merge: true })
  }
}
