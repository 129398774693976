
























import { Component, Vue } from 'vue-property-decorator'
import UserList from '@/components/manage/UserList.vue'
import CompanySelect from '@/components/manage/CompanySelect.vue'
import AuthStore from '@/store/AuthStore'

@Component({
  components: { UserList, CompanySelect }
})
export default class UserIndex extends Vue {
  get existsCompany (): boolean {
    return !!AuthStore.companies.length
  }
}
