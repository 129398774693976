











































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { User } from '@/models/user'
import DisplayTime from '@/components/common/DisplayTime.vue'
import UserItemCompany from '@/components/manage/UserItemCompany.vue'
import UserItemGroup from '@/components/manage/UserItemGroup.vue'
import UserItemActions from '@/components/manage/UserItemActions.vue'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'

@Component({
  components: {
    DisplayTime,
    UserItemCompany,
    UserItemGroup,
    UserItemActions,
    DisplayPhoneNumber
  }
})
export default class UserItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<User>

  get item (): User {
    return this.doc.data()
  }

  get displayName (): string {
    return this.item.displayName ? this.item.displayName : '이름없음'
  }
}
