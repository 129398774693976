













import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { User } from '@/models/user'

@Component({
  components: {}
})
export default class UserItemActions extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<User>

  items = [
    'admin',
    'read',
    'write',
    'manage',
    'company-admin',
    'group-admin'
  ]

  get item (): User {
    return this.doc.data()
  }

  get value (): string[] {
    return this.item.actions
  }

  update (actions: string[]): void {
    this.doc.ref.set({ actions }, { merge: true })
  }
}
