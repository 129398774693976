



















import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import userCollection, { User } from '@/models/user'
import UserItem from '@/components/manage/UserItem.vue'
import { Company } from '@/models/company'
import AuthStore from '@/store/AuthStore'

@Component<UserList>({
  components: {
    UserItem
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class UserList extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<User>[] = []

  @Watch('company')
  onChangeCompany (): void {
    this.subscribe()
  }

  // get items (): Firmware[] {
  //   return this.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
  // }
  get company (): firebase.firestore.DocumentSnapshot<Company> | null{
    return AuthStore.selectedCompany
  }

  get isAdmin (): boolean {
    return AuthStore.isAdmin
  }

  get companies (): firebase.firestore.DocumentSnapshot<Company>[] {
    return AuthStore.companies
  }

  subscribe (): void {
    const ref = this.company
      ? userCollection.where('companies', 'array-contains', this.company.ref)
      : this.isAdmin ? userCollection : userCollection.where('companies', 'array-contains-any', this.companies.map(c => c.ref))

    this.unsubscribe = ref.limit(100).onSnapshot(sn => {
      this.docs = sn.docs
    })
  }
}
